import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Seamless from "../components/Seamless"
import { Heading } from "../components/Hero"
import { Section, Columns } from "../components/Containers"
import { Text } from "../components/Core"

const BasicPage = ({ pageContext, location, data }) => {
  const { language } = pageContext
  const post = data.allBasicPagesJson.nodes[0]

  return (
    <Layout language={language} className="basic-page">
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        lang={language}
      />
      <Heading heading={post.heading} blurb={post.blurb ? post.blurb : ""} />

      <Section>
        <Columns sideColumnsSize={4}>
          <div className="column">
            <Text text={post.body} />
          </div>
        </Columns>
      </Section>

      {post.form && (
        <Section>
          <Columns sideColumnsSize={5}>
            <div
              className="column"
              style={{ maxWidth: "900px", marginInline: "auto" }}>
              <Seamless src={post.form} />
            </div>
          </Columns>
        </Section>
      )}
    </Layout>
  )
}

export const basicPageQuery = graphql`
  query basicPage($title: String!) {
    allBasicPagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        blurb
        body
        form
      }
    }
  }
`

export default BasicPage
